/* PRICE TABLE */
.row-flex {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
}

.price-table {
  max-width: 400px;
  min-height: 320px;
  background-color: #fff;
  border-radius: 0 0 0 25px;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 8px #ccc;
  display: block;
  margin: 10px auto;
  padding: 0 0 8px 0;
  text-align: center;
}

.price-table span {
  display: block;
}

.price-table span:first-child {
  padding: 16px 0 0 0;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bold;
}

.price-table span:nth-child(2) {
  padding: 0 0 16px 0;
}

.price-table span:nth-child(3) {
  padding: 8px 0;
  font-weight: bold;
  font-size: 1.2em;
}

.price-table > ul {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.price-table > ul > li {
  display: block;
  padding: 8px 0;
}

.price-table > ul > li:first-child {
  border-top: 1px solid #ccc;
}

.price-table > ul > li {
  border-bottom: 1px solid #ccc;
}

.price-table a,
.price-table a:active,
.price-table a:focus,
.price-table a:hover,
.price-table a:visited {
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 16px;
  text-decoration: none;
  font-weight: bold;
  transition-duration: 0.2s;
}

/* Colors */
.pt-bg-black span:first-child {
  background-color: #212121;
  color: #fcfcfc;
}

.pt-bg-black span:nth-child(2) {
  background-color: #212121;
  color: #d5d8dc;
}

.pt-bg-black a {
  border: 3px solid #212121;
  color: #212121;
  margin-top: 8px;
}

.pt-bg-black a:hover {
  background-color: #212121;
  color: #fff;
} /* End Color Black */

.pt-bg-green span:first-child {
  background-color: #27ae60;
  color: #fcfcfc;
}

.pt-bg-green span:nth-child(2) {
  background-color: #27ae60;
  color: #d5d8dc;
}

.pt-bg-green a {
  border: 3px solid #27ae60;
  color: #27ae60;
  margin-top: 8px;
}

.pt-bg-green a:hover {
  background-color: #27ae60;
  color: #fff;
} /* End Color Green */

.pt-bg-red span:first-child {
  background-color: #c0392b;
  color: #fcfcfc;
}

.pt-bg-red span:nth-child(2) {
  background-color: #c0392b;
  color: #d5d8dc;
}

.pt-bg-red a {
  border: 3px solid #c0392b;
  color: #c0392b;
  margin-top: 8px;
}

.pt-bg-red a:hover {
  background-color: #c0392b;
  color: #fff;
} /* End Color Red */

.pt-bg-blue span:first-child {
  background-color: #2980b9;
  color: #fcfcfc;
}

.pt-bg-blue span:nth-child(2) {
  background-color: #2980b9;
  color: #d5d8dc;
}

.pt-bg-blue a {
  border: 3px solid #2980b9;
  color: #2980b9;
  margin-top: 8px;
}

.pt-bg-blue a:hover {
  background-color: #2980b9;
  color: #fff;
} /* End Color Blue */
/* END PRICE TABLE */

/* CREATE SHOP STYLES */
.shopErection {
  flex: 9;
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.shopErectionTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.shopErectionTitle {
  font-size: 40px;
  font-family: monospace;
}

.shopErectionAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.shopErectionContainer {
  display: flex;
  margin-top: 20px;
}

.shopErectionShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.shopErectionUpdate {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
}

.shopErectionShowTop {
  display: flex;
  align-items: center;
}

.shopErectionShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.shopErectionShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.shopErectionShowshopErectionname {
  font-weight: 600;
}

.shopErectionShowshopErectionTitle {
  font-weight: 300;
}

.shopErectionShowBottom {
  margin-top: 20px;
}

.shopErectionShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.shopErectionShowInfo {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  color: #444;
}

.shopErectionShowIcon {
  font-size: 16px !important;
}

.shopErectionShowInfoTitle {
  margin-left: 10px;
}

.shopErectionUpdateTitle {
  font-size: 24px;
  font-weight: 600;
}

.shopErectionUpdateForm {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.shopErectionUpdateItem {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.shopErectionUpdateItem > label {
  margin-bottom: 5px;
  font-size: 14px;
}

.shopErectionUpdateInput {
  border: none;
  width: 250px;
  height: 30px;
  border-bottom: 1px solid gray;
}

.shopErectionUpdateRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shopErectionUpdateUpload {
  display: flex;
  align-items: center;
}

.shopErectionUpdateImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.shopErectionUpdateIcon {
  cursor: pointer;
}

.shopErectionUpdateButton {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: darkblue;
  color: white;
  font-weight: 600;
}
/* END CREATE SHOP STYLES */
