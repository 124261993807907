// List shop products
.productList {
  flex: 4;
}

.productListItem {
  display: flex;
  align-items: center;
}

// productInfoItem

.productListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.productListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.productListDelete {
  color: red;
  cursor: pointer;
}

//Add Shop Products

//update shop products
.product {
  flex: 4;
  padding: 20px;
  flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
}

.productTitleContainer {
  display: flex;
  flex-direction: column;
  //   align-items: center;
  //   justify-content: space-between;
}

.productAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.productTop {
  display: flex;
  width: 100%;
}

.productTopLeft,
.productTopRight {
  flex: 1;
}

.productTopRight {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.productInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.productInfoTop {
  display: flex;
  align-items: center;
}

.productName {
  font-weight: 600;
}

.productInfoBottom {
  margin-top: 10px;
}

.productInfoItem {
  width: 150px;
  // width: 100%;
  max-height: 200px;
  display: flex;
  justify-content: space-between;
}

.productInfoValue {
  font-weight: 300;
}

.productBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  width: 100%;
  justify-content: space-between !important;
}

.productForm {
  display: flex;
  justify-content: space-between !important;
  //   margin-right: 20px;
}

.productFormLeft {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.productFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.productFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.productFormLeft > select {
  margin-bottom: 10px;
  width: auto;
}

.productUploadImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.productFormRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.productUpload {
  display: flex;
  align-items: center;
}

.productButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
