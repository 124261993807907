* {
    font-family: $font-1st;
    font-weight: 400;
}

html {
    font-size: 62.5%;
}

body {
    font-size: $document-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    color: $color-heading;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;

    a {
        color: inherit;
    }
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

p {
    font-size: $document-font-size;
    line-height: 1.6em;
    color: $color-text;

    span {
        font-family: inherit;
        color: inherit;
        font-size: inherit;
    }
}

a {
    position: relative;
    color: inherit;
    text-decoration: none;
    @include transition(all 0.4s ease);

    &:hover {
        color: $color-1st;
    }
}

a,
input,
textarea,
button,
select {
    outline: none;
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
    text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

img {
    max-width: 100%;
}

iframe {
    border: 0;
    max-width: 100%;
    width: 100%;
}

.ps-document {
    ul,
    ol {
        margin-bottom: 10px;

        ul,
        ol {
            margin-bottom: 0;
        }
    }

    ul li,
    ol li {
        color: $color-text;
        font-size: $document-font-size;
        line-height: 1.6em;
    }

    p {
        margin-bottom: 15px;

        strong {
            color: $color-heading;
        }
    }
}

figure {
    margin: 0;
}

.ps-badge {
    display: inline-block;
    border-radius: 4px;
    padding: 6px 10px;
    font-weight: 600;
    font-size: 14px;

    &.success {
        color: #669900;
        background-color: #eff4e5;
    }

    &.gray {
        color: #666666;
        background-color: #e9e9e9;
    }
}

.ps-fullfillment {
    position: relative;
    display: inline-block;
    font-weight: 600;
    padding-left: 12px;
    text-transform: capitalize;
    &:before {
        content: '';
        @include vertical-align();
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 10px;
    }

    &.success {
        &:before {
            background-color: #89ce00;
        }
    }

    &.warning {
        &:before {
            background-color: #fcb800;
        }
    }

    &.danger {
        &:before {
            background-color: #e50202;
        }
    }
}

