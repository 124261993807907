.shoppingrightbar {
  flex: 3;
  // margin: 10px;
  // -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // margin-top: 50px;

  margin: 0 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // margin-top: 20px;
  border-radius: 10px;
  // background-color: rgb(251, 252, 255);
  // position: sticky !important;
  // overflow-y: scroll !important;
  // top: 60px;

  height: calc(100vh - 50px);
  overflow-y: scroll;
  position: sticky;
  top: 70px;
}

.shoppingrightbarWrapper {
  // margin: 30px;
}

.shoppingprofileRightbarTop {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.shoppingbirthdayContainer {
  display: flex;
  align-items: center;
}

.shoppingbirthdayImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.shoppingbirthdayText {
  font-weight: 300;
  font-size: 15px;
}

.shoppingrightbarAd {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  margin: 30px 0;
}

.shoppingrightbarTitle {
  margin: 20px 20px;
}

.shoppingrightbarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shoppingrightbarTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.shoppingrightbarInfo {
  margin-bottom: 30px;
}

.shoppingrightbarInfoItem {
  margin-left: 20px;
  margin-bottom: 10px;
}

.shoppingrightbarInfoKey {
  font-weight: 500;
  margin-right: 15px;
  color: #555;
}

.shoppingrightbarInfoValue {
  font-weight: 300;
}

.shoppingrightbarFollowings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shoppingrightbarFollowing {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.shoppingrightbarFollowingImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.block-content,
.item,
.product-name {
  margin-right: 10px;
}
