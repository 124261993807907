.newspost {
  flex: 7;
  align-items: center;
  margin-left: 10px;
  height: calc(100vh-60px);
}

.newspostWrapper {
  padding: 20px;
}
