.userRight {
  flex: 9;
}

.userBottom {
  display: flex;
  //   flex: 5;
}
.rightbarpanel {
  padding: 0;
  margin: 0;
}

// user area css
.userList {
  flex: 9;

  align-items: center;
  margin-left: 10px;
  height: calc(100vh-200px) !important;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}
.userListEditIcon {
  margin-right: 15px;
  color: #3bb077;
  cursor: pointer;
}

.userListDelete {
  color: red;
  cursor: pointer;
}
