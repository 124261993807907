.widgetLg {
  flex: 2;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 20px;
  margin-bottom: 20px;
}

.widgetLgTitle {
  font-size: 22px;
  font-weight: 600;
}

.widgetLgTable {
  width: 100% !important;
  border-spacing: 35px !important;
  margin: 0;
}

.widgetLgTh {
  text-align: left !important;
  font-weight: 600;
  // margin: 5px 0;
}

// .widgetLgTr {
//   padding: 10px;
//   margin: 10px 0 !important;
//   margin-top: 20px !important;
// }

.widgetLgUser {
  display: flex;
  align-items: center;
  font-weight: 600;
  //
}

.widgetLgImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  // margin-right: 10px;
  margin: 5px 10px 0;
}

.widgetLgDate,
.widgetLgAmount {
  font-weight: 300;
}

.widgetLgButton {
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
}

.widgetLgButton.Approved {
  background-color: #e5faf2;
  color: #3bb077;
}

.widgetLgButton.Declined {
  background-color: #fff0f1;
  color: #d95087;
}

.widgetLgButton.Pending {
  width: 100% !important;
  background-color: #ebf1fe;
  color: #2a7ade;
}

.widgetLgButton.Approved {
  background-color: #e5faf2;
  color: #3bb077;
}
// my own them
.widgetLgButton.OrderPaid {
  width: 100% !important;
  background-color: #e5faf2;
  color: #3bb077;
}

.widgetLgButton.OrderNotPaid {
  width: 100% !important;
  background-color: #fff0f1;
  color: #d95087;
}
.widgetLgButton.Pending {
  width: 100% !important;
  background-color: #ebf1fe;
  color: #2a7ade;
}

.widgetLgButton.PendingDelivery {
  width: 100% !important;
  // background-color: #fff0f1;
  background-color: #adc0d8;
  color: #2a7ade;
}
