.sidebarFriend {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.sidebarFriendImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
