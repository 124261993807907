.adminpost {
  // flex: 9;
  width: 100%;
  align-items: center;
  margin-left: 10px;
  height: calc(100vh-60px);
}

.adminpostWrapper {
  // padding: 10px;
}

.admin {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 20px 0;
}

.adminWrapper {
  // padding: 10px;
}
.homeWidgets {
  display: flex;
  margin: 20px;
}
