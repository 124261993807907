.newspost {
  flex: 7;
  align-items: center;
}

.newspostWrapper {
  padding: 20px;
}
.news {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 30px 0;
}

.newsWrapper {
  padding: 10px;
}
