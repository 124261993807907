.feed {
  flex: 9;
  align-items: center;
  margin-left: 10px;
  height: calc(100vh-60px);
}

.feedWrapper {
  // padding: 20px;
  // display: flex;
  // // flex-wrap: wrap;
  // // justify-content: space-between;
  // // margin: 10px;

  width: 100%;
  // height: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 20px 0;
}
