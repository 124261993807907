.profile {
  display: flex;
}
.profileRight {
  flex: 9;
}

.profileRightBottom {
  display: flex;
}
