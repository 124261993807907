.adminrightbar {
  flex: 3;

  // height: calc(100vh-60px);
  margin: 0 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // margin-top: 20px;
  border-radius: 10px;
  background-color: teal;
  // position: sticky !important;
  // overflow-y: scroll !important;
  // top: 60px;

  height: calc(100vh - 50px);
  overflow-y: scroll;
  position: sticky;
  top: 50px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #b5ced8ce;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}

.adminbarWrapper {
  padding: 20px;
  color: rgb(141, 73, 73);
}
.adminbarMenu {
  margin-bottom: 10px;
}
.adminbarTitle {
  font-size: 13px;
  color: lightgrey;
}
.link {
  list-style: none;
  background-color: white;
}
.adminbarList {
  list-style: none;
  //   padding: 5px;
  //   display: flex;
  //   flex-direction: column;
}
.adminbarListItem {
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-left: 0;
}

.adminbarListItem.active,
.adminbarListItem:hover {
  background-color: rgb(237, 243, 235);
}
.adminbarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
