.product {
  display: flex;
  flex: 7;
  border-radius: 10px;
  align-items: center;
  // -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // margin: 30px 0;
}

.productWrapper {
  flex-direction: row;
  padding: 20px;
}
