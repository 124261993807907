.productRight {
  flex: 9;
}

.productBottom {
  display: flex;
  //   flex: 5;
}
.rightbarpanel {
  padding: 0;
  margin: 0;
}

// product area css
.productList {
  flex: 9;

  align-items: center;
  margin-left: 10px;
  //   height: 100% !important;
  //   height: calc(100vh-60px);
  height: calc(100vh - 50px);
  //   position: sticky;
  font-size: 24px !important;
  top: 50px;
}
.producttable {
  height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  font-size: 24px !important;
  //   margin: 20px;
  //   margin-right: 20px;
}
.productListProduct {
  display: flex;
  align-items: center;
}

.productListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.productListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}
.productListEditIcon {
  margin-right: 15px;
  color: #3bb077;
  cursor: pointer;
}

.productListDelete {
  color: red;
  cursor: pointer;
}
