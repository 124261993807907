.rightbar {
  flex: 3;
}

.rightbarWrapper {
  padding: 20px 20px 0 0;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin-top: 50px;
}

.profileRightbarTop {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.birthdayContainer {
  display: flex;
  align-items: center;
}

.birthdayImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.birthdayText {
  font-weight: 300;
  font-size: 15px;
}

.rightbarAd {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  margin: 30px 0;
}

.rightbarTitle {
  margin: 20px 20px;
}

.rightbarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rightbarTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.rightbarInfo {
  margin-bottom: 30px;
}

.rightbarInfoItem {
  margin-left: 20px;
  margin-bottom: 10px;
}

.rightbarInfoKey {
  font-weight: 500;
  margin-right: 15px;
  color: #555;
}

.rightbarInfoValue {
  font-weight: 300;
}

.rightbarFollowings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rightbarFollowing {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.rightbarFollowingImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
