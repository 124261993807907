.share {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  //   background-color: royalblue;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin-top: 20px;
}
.shareWrapper {
  padding: 10px;
}
.shareTop {
  display: flex;
  align-items: center;
}
.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 10px;
}
.shareInput {
  border: none;
  width: 80%;
  margin-left: 10px;
}
.shareInput:focus {
  outline: none;
}

.shareHr {
  margin: 20px;
}

.shareBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareOptions {
  display: flex;
  margin-left: 20px;
}

.shareOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.shareIcon {
  font-size: 18px;
}
.shareOptionText {
  font-size: 14px;
  font-weight: 500;
}
.shareButton {
  border: none;
  padding: 7px;
  border-radius: 5px;
  background-color: darkred;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: white;
}
