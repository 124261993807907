.signin {
  flex: 5;
}

.signinWrapper {
  padding: 20px 20px 0 0;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 50px 20px;
}

.signinTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signinTopLeft {
  display: flex;
  align-items: center;
}

.signinProfileImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.signinUsername {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
}

.signinDate {
  font-size: 12px;
}

.signinCenter {
  margin: 20px 0;
}

.signinImg {
  margin-top: 20px;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}

.signinBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signinBottomLeft {
  display: flex;
  align-items: center;
}

.likeIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.signinLikeCounter {
  font-size: 15px;
}

.signinCommentText {
  cursor: pointer;
  border-bottom: 1px dashed gray;
  font-size: 15px;
}

.loginBox {
  height: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginInput {
  height: 35px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  height: 35px;
  border-radius: 10px;
  border: none;
  background-color: #1775ee;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.loginForgot {
  text-align: center;
  color: #1775ee;
  cursor: pointer;
}

.loginRegisterButton {
  width: 60%;
  align-self: center;
  height: 35px;
  border-radius: 10px;
  border: none;
  background-color: #42b72a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
