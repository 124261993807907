.shopping {
  display: flex;
}
.shoppingRight {
  flex: 9;
}

.shoppingCover {
  height: 320px;
  position: relative;
}
.shoppingCoverImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.shoppingUserImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
  border: 3px solid white;
}
.shoppingInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shoppingInfoName {
  font-size: 24px;
}
.shoppingInfoDesc {
  font-weight: 300;
}
.shoppingDivider {
  margin: 0 15px;
}
.shoppingRightBottom {
  display: flex;
}
