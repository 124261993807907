.socialsidebar {
  flex: 3;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  position: sticky;
  top: 50px;
  //   background-color: yellowgreen;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}

.sidebarWrapper {
  padding: 20px;
}
.sidebarList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebarListItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.sidebarIcon {
  margin-right: 20px;
}
.sidebarButton {
  width: 150px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
}

.sidebarHr {
  margin: 20px 0;
}

.sidebarFriendList {
  padding: 0;
  margin: o;
  list-style: none;
  cursor: pointer;
}
.sidebarFriendLis:hover {
  text-decoration: none;
  color: #e83f33;
}
