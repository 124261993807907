.topbarContainer {
  height: 50px;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  // position: sticky;
  position: fixed;
  top: 0;
  z-index: 999;
  color: white;

  .topbarLeft {
    flex: 3;
    //   background-color: red;
    display: flex;
    align-items: center;

    .logo {
      font-size: 24px;
      margin-left: 20px;
      font-weight: bold;
      color: white;
      cursor: pointer;
    }

    .topbarLink {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .topbarCenter {
    flex: 5;
    //   background-color: teal;

    .searchbar {
      width: 75%;
      height: 25px;
      background-color: white;
      border-radius: 30px;
      display: flex;
      align-items: center;
    }
    .searchIcon {
      font-size: 20px !important;
      margin-left: 10px;
    }

    .searchInput {
      border: none;
      width: 70%;
    }
    .searchInput:focus {
      outline: none;
    }
  }

  .topbarRightAuthLinks {
    flex: 1;
    //   background-color: red;
    display: flex;
    margin-right: 5px;
    padding: 3px 3px;
    justify-content: space-evenly;
    // right: 5px;
  }

  .topbarRight {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    //   background-color: tomato;

    .topbarLink {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 14px;
      cursor: pointer;
    }
    .topbarIcons {
      display: flex;
    }
    .topbarIconItem {
      margin-right: 15px;
      cursor: pointer;
      position: relative;
    }
    .topbarIconBadge {
      // margin-top: 20px;
      width: 15x;
      height: 15px;
      background-color: red;
      border-radius: 50%;
      color: white;
      position: absolute;
      top: -5px;
      right: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatars {
      display: flex;

      .avatarItem {
        margin-left: 5px;
        margin-right: 10px;
        .icon {
          margin: 0, 5px;
          cursor: pointer;
          // font: 14px;
        }
        .options {
          display: none;
          background-color: black;
          border-radius: 5px;
        }
        span {
          padding: 10px;
          cursor: pointer;
          margin-right: 20px;
        }
        &:hover {
          .options {
            display: flex;
            flex-direction: column;
            position: absolute;
          }
        }
      }
      .profile {
        .icon {
          margin: 0, 5px;
          cursor: pointer;
          font: 14px;
        }
        .options {
          display: none;
          background-color: black;
          border-radius: 5px;
        }
        &:hover {
          .options {
            display: flex;
            flex-direction: column;
            position: absolute;
          }
        }
      }

      .topbarImg {
        // margin-top: 20px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
