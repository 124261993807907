.singleproduct {
  flex: 7;
  // margin: 10px 0;
  align-items: center;
  margin-left: 10px;
  height: calc(100vh-60px);
}

.singleproductWrapper {
  padding: 20px;
}
.product {
  // width: 100%;
  // border-radius: 10px;
  // -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  // box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 20px 0;
}

.productWrapper {
  padding: 10px;
}
