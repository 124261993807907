.guest {
  display: flex;
}
.guestRight {
  flex: 9;
}
.guestCover {
  height: 320px;
  position: relative;
}
.guestCoverImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.guestImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
  border: 3px solid white;
}
.guestInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.guestInfoName {
  font-size: 24px;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
}
.guestInfoDesc {
  font-weight: 300;
}
.guestDivider {
  margin: 0 15px;
}
.guestRightBottom {
  display: flex;
}
