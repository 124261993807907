.pagination {
    margin: 0;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-size: 0;

    li {
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
        > a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            z-index: 30;
            color: #999;
            border: none;
            background-color: #f5f5f5;
            color: $color-heading;
            vertical-align: middle;
            font-size: 13px;

            i {
                font-size: 10px;
                font-weight: 600;
                margin-left: 4px;
            }

            &:hover {
                color: #fff;
                background-color: $color-1st;
            }
        }

        &.active {
            border: none;
            a {
                color: #fff;
                background-color: $color-1st;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

