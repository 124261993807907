.first-img {
  //   margin-top: 10px;
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
.item {
  margin: 20px;
}

.product-item .pr-img-area {
  overflow: hidden;
  position: relative;
  border: 1px #e5e5e5 solid;
}
.product-item .pr-img-area a {
  display: block;
}
.product-item .pr-img-area > a::before {
  background: #444 none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: all 0.9s ease 0s;
  width: 100%;
  z-index: 9;
}
.product-item:hover .pr-img-area a::before {
  opacity: 0.1;
}
.product-item .item-inner img.hover-img {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.9s ease 0s;
}
.product-item .item-inner:hover img.hover-img {
  opacity: 1;
}
.pr-img-area {
  overflow: hidden;
  position: relative;
}
.add-to-cart-mt {
  background: #e83f33;
  border: 0 none;
  bottom: 0;
  font-size: 12px;
  left: -100%;
  line-height: 10px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease-out 0s;
  width: 100%;
  padding: 12px 0;
  color: #fff;
}
.add-to-cart-mt:hover {
  background: #e83f33;
  color: #fff;
}
.add-to-cart-mt span {
  margin-left: 5px;
}
.product-item .item-inner:hover .product-thumbnail .add-to-cart-mt {
  left: 0;
}
.product-item .item-inner:hover .product-thumbnail .pr-button {
  background: #f2f2f2;
}
.pr-button .mt-button {
  width: 33.3%;
  float: left;
  text-align: center;
  border-right: 1px solid;
}
.pr-button {
  border-top: 1px solid #e5e5e5;
  overflow: hidden;
  transition: all 0.5s ease-out 0s;
}
.pr-info-area .product-name a:hover,
.pr-button .mt-button a:hover {
  color: #f47e1e;
}
.pr-button .mt-button {
  border-right: 1px solid #e5e5e5;
  float: left;
  text-align: center;
  width: 33.3%;
}
.pr-button .mt-button a {
  color: #fff;
  display: block;
  font-size: 16px;
  padding: 10px 3px 8px;
  font-weight: normal;
  background-color: #444;
  border-radius: 2px;
}
.pr-info-area .pr-button .s-button a:hover {
  background: #e83f33;
  color: #fff;
}
.pr-button .s-button a {
  color: #fff;
}
.pr-img-area img {
  width: 100%;
}
.product-item .pr-button {
  border: 0 none;
  position: absolute;
  top: 3%;
  opacity: 0;
  z-index: 12;
  padding-left: 4%;
}
.product-item .item-inner:hover .product-thumbnail .pr-button {
  background: none;
  opacity: 1;
}
.pr-button .mt-button a:hover {
  background: #e83f33;
  color: #fff;
  border: 0px #e83f33 solid;
}
.pr-button .mt-button {
  border-right: 0 none;
  float: none;
  margin-bottom: 8px;
  text-align: center;
  transform: scale(0);
  transition: all 0.4s ease 0s;
  width: 40px;
  line-height: 20px;
}
.product-item .item-inner:hover .product-thumbnail .mt-button,
.on-sale-product .item-inner:hover .product-thumbnail .mt-button {
  transform: scale(1);
}
.product-item .item-inner .item-info {
  text-align: center;
  padding-top: 12px;
}
.product-item .item-inner .item-info .item-title {
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 400;
}
.product-item .item-inner .item-info .item-title a {
  display: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 500;
}
.product-item .item-inner {
  position: relative;
  overflow: hidden;
  padding-bottom: 15px;
}
.product-item .item-inner:hover {
  box-shadow: 0 0 2px #dedede;
}
.product-item {
  margin: 0 12px;
}
.products-grid .item .product-item {
  margin: 0px;
}
.owl-carousel .owl-wrapper-outer {
  padding: 5px 0px;
}
.product-item .item-inner .item-info .item-title a:hover {
  color: #e83f33;
}
.product-item .item-inner .item-info .pro-action {
  position: absolute;
  bottom: -50px;
  width: 100%;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
}
.product-item:hover .item-inner .item-info .pro-action {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.product-item .item-inner .item-info .add-to-cart {
  padding: 9px 12px;
  font-size: 12px;
  width: 100%;
}
.product-item .item-inner .item-info .add-to-cart i {
  font-size: 16px;
  vertical-align: -1px;
}
.product-item .item-inner:hover .item-info .add-to-cart {
  color: #fff;
  background-color: #e83f33;
  border: 1px #e83f33 solid;
  width: 100%;
  transition: 0.5s all ease;
}
.product-item .item-inner .item-info .add-to-cart:hover {
  background-color: #444;
  border: 1px #444 solid;
  transition: 0.5s all ease;
}
.product-item .item-inner .item-info .add-to-cart span {
  margin-left: 5px;
}
#best-sale-slider.product-flexslider {
  margin: 20px -12px;
}
.best-sale-pro .slider-items-products .owl-buttons .owl-prev {
  top: 15%;
}
.best-sale-pro .slider-items-products .owl-buttons .owl-next {
  top: 15%;
}
