* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.componentsContainer {
  display: flex;
  flex: 12;
}

//right part of web layout
.shopRight {
  flex: 9;
}

.shopBottom {
  display: flex;
  //   flex: 5;
}
.rightbarpanel {
  padding: 0;
  margin: 0;
}

.topbarLinks {
  color: white;
  list-style: none;
  padding: 0px;
}

// .pageRight {
//   flex: 9;
// }
// .homeRight {
//   flex: 9;
// }

// .homeContainer {
//   display: flex;
// }

// pages
.shopping {
  display: flex;
}
.shoppingRight {
  flex: 9;
}

.shoppingCover {
  height: 320px;
  position: relative;
}
.shoppingCoverImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.shoppingUserImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
  border: 3px solid white;
}
.shoppingInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shoppingInfoName {
  font-size: 24px;
}
.shoppingInfoDesc {
  font-weight: 300;
}
// .shoppingDivider {
//   margin: 0 15px;
// }
.shoppingRightBottom {
  display: flex;
}

.loadingPaper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  height: 39vh;
}
