.guestHome {
  display: flex;
}

.guestHomeRight {
  flex: 12;
  //   height: 90vh;
  position: relative;

  .guestHomeCover {
    height: 400px;
    position: relative;

    .guestHomeCoverImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .info {
      width: 35%;
      position: absolute;
      left: 50px;
      bottom: 50px;
      color: white;
      display: flex;
      flex-direction: column;

      img {
        width: 400px;
      }

      .desc {
        margin: 20px 0px;
        font-size: 14px;
      }

      .buttons {
        display: flex;

        button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 500;
          margin-right: 10px;
          cursor: pointer;

          &.play {
            background-color: white;
            color: black;
          }

          &.more {
            background-color: gray;
            color: white;
          }

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .guestHomeRightBottom {
    display: flex;
  }
}

// .guestHomeUserImg {
//   width: 150px;
//   height: 150px;
//   border-radius: 50%;
//   object-fit: cover;
//   position: absolute;
//   left: 0;
//   right: 0;
//   margin: auto;
//   top: 150px;
//   border: 3px solid white;
// }
// .guestHomeInfo {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }
// .guestHomeInfoName {
//   font-size: 24px;
// }
// .guestHomeInfoDesc {
//   font-weight: 300;
// }
// .guestHomeDivider {
//   margin: 0 15px;
// }
