.feed {
  flex: 5;
  align-items: center;
  margin-left: 10px;
  // height: calc(100vh-60px);
}

.feedWrapper {
  padding: 20px;
}
