$space-xxxxs: var(--space-xxxxs);
$space-xxxs: var(--space-xxxs);
$space-xxs: var(--space-xxs);
$space-xs: var(--space-xs);
$space-sm: var(--space-sm);
$space-md: var(--space-md);
$space-lg: var(--space-lg);
$space-xl: var(--space-xl);
$space-xxl: var(--space-xxl);
$space-xxxl: var(--space-xxxl);
$space-xxxxl: var(--space-xxxxl);



