.productFilter {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  //   background-color: royalblue;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}
.productFilterWrapper {
  padding: 20px;
}
.productFilterTop {
  display: flex;
  align-items: center;
}
.productFilterProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 10px;
}
.productFilterInput {
  border: none;
  width: 80%;
  margin-left: 10px;
}
.productFilterInput:focus {
  outline: none;
}

.productFilterHr {
  margin: 20px;
}

.productFilterBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productFilterOptions {
  display: flex;
  margin-left: 20px;
}

.productFilterOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.productFilterIcon {
  font-size: 18px;
}
.productFilterOptionText {
  font-size: 14px;
  font-weight: 500;
}
.productFilterButton {
  border: none;
  padding: 7px;
  border-radius: 5px;
  background-color: darkred;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: white;
}
