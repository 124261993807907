.home {
  display: flex;
  // width: 100%;
}
.homeRight {
  flex: 9;
}

.homeContainer {
  display: flex;
}
