.adminRight {
  flex: 9;
}

.adminBottom {
  display: flex;
  //   flex: 5;
}
.rightbarpanel {
  padding: 0;
  margin: 0;
}
